<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-section">
        <h3>关于我们</h3>
        <p>论文说致力于利用AI技术，让学术传播更加简单高效。</p>
      </div>

      <div class="footer-section">
        <h3>快速链接</h3>
        <ul>
          <li><a href="#">使用教程</a></li>
          <li><a href="#">常见问题</a></li>
        </ul>
      </div>

      <div class="footer-section">
        <h3>联系方式</h3>
        <ul>
          <li>邮箱：paperwithvideo@gmail.com</li>
          <li>
            <button @click="openWeChatModal" class="mt-2 wechat-button">
              加入微信交流群
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-bottom">
      <p class="copyright">© {{ currentYear }} 论文说. 保留所有权利</p>
      <div class="footer-links">
        <a href="#">使用条款</a>
        <a href="#">隐私政策</a>
      </div>
    </div>

    <!-- 微信交流群弹出图片模态框 -->
    <div
      v-if="showWeChatModal"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div class="bg-white p-6 rounded-lg w-96 shadow-lg relative">
        <h3 class="text-2xl mb-4 text-center" style="color: black">微信扫码</h3>
        <img
          src="/static/wechat.jpg"
          alt="微信交流群"
          class="rounded-lg max-w-full h-auto"
        />
        <!-- 添加关闭按钮的右上角图标 -->
        <button
          @click="closeWeChatModal"
          class="absolute top-2 right-4 text-gray-600 hover:text-gray-800"
        >
          ×
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      showWeChatModal: false, // 控制微信交流群模态框的显示状态
      currentYear: new Date().getFullYear(), // 添加当前年份
    };
  },
  methods: {
    /**
     * 打开微信交流群模态框
     */
    openWeChatModal() {
      this.showWeChatModal = true;
    },
    /**
     * 关闭微信交流群模态框
     */
    closeWeChatModal() {
      this.showWeChatModal = false;
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #1e293b;
  color: #f8fafc;
  padding: 4rem 2rem 2rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  margin-bottom: 3rem;
}

.footer-section h3 {
  color: white;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer-section p,
.footer-section a,
.footer-section li {
  color: #cbd5e1;
}

.footer-section a:hover {
  color: white;
}

.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
  border-top: 1px solid #334155;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.footer-links a {
  color: #cbd5e1;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: white;
}

/* 微信交流群按钮样式 */
.wechat-button {
  background-color: #10b981;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.wechat-button:hover {
  background-color: #059669;
}

/* 微信交流群模态框样式 */
.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-black {
  background-color: #000000;
}

.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5); /* 确保只背景颜色半透明 */
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.bg-white {
  background-color: #ffffff;
}

.p-6 {
  padding: 1.5rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.w-96 {
  width: 300px;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bg-gray-800 {
  background-color: #2d3748;
}

.text-white {
  color: #ffffff;
}

.hover\:bg-gray-700:hover {
  background-color: #4a5568;
}

/* 关闭按钮样式 */
button.absolute {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

button.absolute:focus {
  outline: none;
}
</style>
